@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    /* --body: #F1F1F4; */
    --body: #EDECFC ;
    --blue: #1E398F;
    --light-blue: #E5F0FF;
    --dark-blue:#2B3674;
    --white:#fff;
    --black: #000;
    --light-black: #0F172A;
    --orange: #EE7100;
    --red: #A30D11;
    --light-gray: #F8F8FC;
    
    --hover-gray: #99b6b1;
    --extra-medium-gray: #e4e4e4;
    --dark-gray: #313e3b;
    --medium-gray: #828c8a;

    --inter: "Inter", sans-serif;
  
    
}
body{
    font-family: "Inter", sans-serif;
    background-color: #fefefe;
}
.different-font{
  font-family: "Play", sans-serif !important;
  font-weight: 700;
  font-style:normal;
}
nav li a,
nav li span,
footer div span,
footer div h4,
footer a span,
footer li a{
  font-family: 'Times New Roman', Times, serif;
}

main{
    scroll-behavior: smooth;
}
button,
a,
input{
  font-family: "Space Grotesk", sans-serif;
}
.container{
    margin: auto;
    max-width: 1240px;
}

#onclick{
    display: none;
}
  


.langfill{
  stroke: #000;
}


@media screen and (max-width: 1220px){
  .container{
    padding-left: 16px;
    padding-right: 16px;
  } 
}


.lbl-resp{
  /* position: relative;
  float: right; */
  cursor: pointer;
  display: none;
}

.inp_check{
  display: none;
}

@media screen and (max-width: 1000px) { 
    .lbl-resp{
      display: block;
    }
  
    .rsp-header{
      margin-top: 100px;
    }
   
    .menu{
      margin: 0px;
      padding: 0px;
      width: 100%;
    }
  
    .menu label{
      right: 30px;
      
    }
    .menu ul{
      position: absolute;
      transition: 1s;
      left: -225px;
      margin-top: 28px ;
      margin-left: 0px;
      width: 225px;
      flex-direction: column;
      background-color: #fff;
      height: 100vh;
      z-index: 990;
  
    }
  
    .menu ul li{
      display: block;
      width: 100%;
      padding: 0 20px;  
      
    }
  
    .menu ul li a{
      width: 100%;
    }
  
    #onclick:checked~ul{
      left: 0px;
    }
   
  }



  /* test */

  .content {
    transition: background-color 0.3s ease;
  }

  .bg-white {
    background-color: var(--white);
  }

  .bg-lightblue {
    background-color: var(--orange)
  }
  .card-background{
    background-image: url('../images/Baker-Library-Harvard-Business-School-Boston-University.webp')
}
.list-design li{
  margin-left: 20px !important;
}

/* Single Exams */


.innertext p{
  width: 100%;
  color: #828c8a !important;
  font-size: 17px !important;
  line-height: 30px !important;
  font-weight: 500 !important;



}
.singleexamheader p{
  color: #828c8a !important;
  font-size: 17px !important;
  line-height: 30px !important;
  font-weight: 400 !important;
}
.innertext p strong,
.innertext p b{
  color: #313e3b !important;
  font-weight: 600 !important; 
  font-size: 18px !important;


}

.innertext li{
  color: #313e3b !important;
  line-height: 30px !important;
  font-size: 17px !important;
  font-weight: 400 !important;


}

.slider-overflow{
  overflow-x: auto;
}



/* slide animation */

   /* Base styles */
   .hover-effect {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #313e3b;
    font-size: 24px;
    padding-bottom: 10px;
    padding-left: 54px ;
    padding-right: 54px;
}


    /* Border styles */
    .hover-effect::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      right: 100%;
      width: 0;
      height: 2px;
      background-color: #313e3b;
      transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
      transform: translateX(-50%);
  }

    /* Hover effect */
    .hover-effect:hover::before {
      width: calc(100% - 0px); /* Adjust as needed */
      left: 50%; /* Adjust as needed */
     
    }



/* play video */

.play_btn {
  text-decoration: none;
  border: 2px dotted white;
  color: aliceblue;
  border-radius: 50%;
  padding: 15px;
  position: relative;
}



.play_btn::before {
  position: absolute;
  content: '';
  bottom: -7px;
  top: -7px;
  left: -7px;
  right: -7px;
  background-color: #fff;
  border-radius: 50%;
  /* border: 4px solid #fff; */
  margin: 0px;
  opacity: 0.8;
  transform-origin: center;
  animation: anim 1500ms linear 400ms infinite;
}

@keyframes anim {
  from {
      margin: 0px;
      opacity: 1;
  }

  to {
      margin: -15px;
      opacity: 0;
  }
}

/* support  */
::placeholder {
  color: var(--dark-gray) !important; 
  opacity: 1; /* Firefox */
}

input:focus {
  outline: none; 
}


/* Graduents card */

.Card8ImgCont{
  clip-path: polygon(0 0, 100% , 100% 100%, 0 50%);

}

.background-image-cont{
  height: 191px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%);
}

/* Footer */  

.footerinp::placeholder {
  color: var(--medium-gray) !important; 
  opacity: 1; /* Firefox */
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

/* Blog Page */

@media screen and (max-width: 1110px){
  .blog-container{
    flex-direction: column;
    width: 100%;
  }

  .blog-card{
    width: 100%;
  }

  
}


/* Sağ okun rengini değiştir */
.slick-next {
  background-color: blue; /* Arka plan rengi mavi */
}

/* Sol okun rengini değiştir */
.slick-prev {
  background-color: red; /* Arka plan rengi kırmızı */
}




/* star rating */

.star-rating {
  display: flex;
  flex-direction: row;
}

.star-rating button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  font-size: 24px;
}

.star-rating .on .star {
  color: var(--orange);
}

.star-rating .off .star {
  color: #ddd;
}

/* Diploma Card */

.dp-bg{
  background-image: url(../images/backgroundDiploma.jpg);
}


/* Premium Card */

.bg_cricle{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



/* Popup Card */

.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Arka planı karartmak için */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Yüksek bir z-index değeri verin */
}

.popup {
  background: #fff;
  /* border-radius: 10px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 999; /* Popup içeriği için daha yüksek bir z-index değeri verin */
}


/* Diplom PopUp */

.popup-bg1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Arka planı karartmak için */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 990; /* Yüksek bir z-index değeri verin */
}

.popup1 {
  background: #fff;
  /* border-radius: 10px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 990; /* Popup içeriği için daha yüksek bir z-index değeri verin */
}

/* Thanks Popup */

.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Arka planı karartmak için */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 990; /* Yüksek bir z-index değeri verin */
}

.success-popup-content {
  background: #fff;
  /* border-radius: 10px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 990; /* Popup içeriği için daha yüksek bir z-index değeri verin */
}



/* Thanks PopUp */

.popup-bg-thanks {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Arka planı karartmak için */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 990; /* Yüksek bir z-index değeri verin */
}

.popup-thanks {
  background: #fff;
  /* border-radius: 10px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 990; /* Popup içeriği için daha yüksek bir z-index değeri verin */
}


/* Gallery */


.gallery-container {
  display: flex;
  align-items: center;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 10px;
}

.image img {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.nxt_btn {
  background-color: var(--orange);
  color: white;
  border: none;
  border-radius: 50px;
  padding:20px;
  display: inline-block;

  margin: 12px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}



.embed_video iframe{
  width: 100%;
  height: 202px;
  border-radius: 8px 8px 0px 0px;
}

.embed_header_video iframe {
  width: 100%;
  height: 450px;
  border-radius: 8px;
}

@media screen and (max-width: 850px) {
  .embed_header_video iframe{
    height: 300px;
  }
}
@media screen and (max-width: 440px) {
  .embed_header_video iframe{
    height: 250px;
  }
}


/* Card Text  */

.card19text ol li,
.card19text ul li{
  font-size: 15px;
  font-weight: 500;
  color: #313e3b;
  line-height: 25px;
  list-style-type: disc;
  list-style-position: inside;
}

.card19text ol,
.card19text ul{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}



.list-cricle{
  list-style-type: circle;
}




.banner {
  display: inline-block;
  background-color: #ff7f00;
  color: white;
  font-size: 20px;
  padding: 10px 20px;
  position: relative;
  max-width: 250px;
}



.banner .arrow {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #ff7f00;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}

/* LOADER STYLES */
.loader {
  display: block;
  position: absolute;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid var(--orange);
  width: 36px;
  height: 36px;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Custom scrollbar gizleme stili */
.scrollbar-hidden {
  scrollbar-width: none; /* Firefox için */
  -ms-overflow-style: none; /* Internet Explorer ve Edge için */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Chrome, Safari ve Edge için */
}

/* select option */

select {
  appearance: none; /* iOS ve diğer tarayıcılar için varsayılan stili kaldırır */
  -webkit-appearance: none; /* iOS için */
  -moz-appearance: none; /* Firefox için */
  background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center; /* Özel ok simgesi ekleyebilirsiniz */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

/* Option styling */
option {
  font-size: 16px;
  font-weight: 500;
}




